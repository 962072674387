var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"settingPasswordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveChange.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.labelOldPassword,"label-for":"account-old-password"}},[_c('validation-provider',{attrs:{"mode":"eager","name":"OldPassword","vid":"currentPassword","rules":"min:4|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-old-password","name":"old-password","type":_vm.passwordFieldTypeOld,"placeholder":_vm.labelOldPassword},model:{value:(_vm.passwordValueOld),callback:function ($$v) {_vm.passwordValueOld=$$v},expression:"passwordValueOld"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconOld},on:{"click":_vm.togglePasswordOld}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"account-new-password","label":_vm.labelNewPassword}},[_c('validation-provider',{attrs:{"mode":"eager","name":"NewPassword","vid":"newPassword","rules":"min:4|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-new-password","type":_vm.passwordFieldTypeNew,"name":"new-password","placeholder":_vm.labelNewPassword},model:{value:(_vm.newPasswordValue),callback:function ($$v) {_vm.newPasswordValue=$$v},expression:"newPasswordValue"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconNew},on:{"click":_vm.togglePasswordNew}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"account-retype-new-password","label":_vm.labelRetypeNewPassword}},[_c('validation-provider',{attrs:{"mode":"eager","name":"RetypePassword","vid":"retype-password","rules":"min:4|max:80|confirmed:newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-retype-new-password","type":_vm.passwordFieldTypeRetype,"name":"retype-password","placeholder":_vm.labelRetypeNewPassword},model:{value:(_vm.RetypePassword),callback:function ($$v) {_vm.RetypePassword=$$v},expression:"RetypePassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconRetype},on:{"click":_vm.togglePasswordRetype}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-75",attrs:{"cols":"12"}},[_c('b-alert',{staticClass:"mb-50 p-1",attrs:{"show":!_vm.sendEmail2,"variant":"warning"}},[_vm._v(" "+_vm._s(_vm.$t("Para sua segurança, os dados da sua conta só poderão ser modificados mediante a confirmação de um código enviado para seu e-mail."))+" "),_c('div',{staticClass:"mt-1"},[_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.sendCode}},[_vm._v(_vm._s(_vm.$t("Clique aqui para receber o código no e-mail")))])],1)]),_c('alert',{staticClass:"mb-1",attrs:{"text":_vm.textAlert,"color":_vm.colorAlert,"showAlert":_vm.showAlert},on:{"changeVariableAlert":function (val) { return (_vm.showAlert = val); }}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Código de segurança')}},[_c('validation-provider',{attrs:{"mode":"eager","name":"securityCode","vid":"securityCode","rules":"min:10|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"code","placeholder":_vm.$t('Digite o código de segurança'),"state":errors.length > 0 ? false : null},model:{value:(_vm.securityCode),callback:function ($$v) {_vm.securityCode=$$v},expression:"securityCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1 mr-1",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t("Salvar alterações"))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t("Redefinir"))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }