<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="settingPasswordForm" #default="{ invalid }">
      <b-form @submit.prevent="saveChange">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group :label="labelOldPassword" label-for="account-old-password">
              <validation-provider #default="{ errors }" mode="eager" name="OldPassword" vid="currentPassword"
                rules="min:4|max:80">
                <b-input-group class="input-group-merge">
                  <b-form-input id="account-old-password" v-model="passwordValueOld" name="old-password"
                    :type="passwordFieldTypeOld" :placeholder="labelOldPassword" />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconOld" class="cursor-pointer" @click="togglePasswordOld" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group label-for="account-new-password" :label="labelNewPassword">
              <validation-provider #default="{ errors }" mode="eager" name="NewPassword" vid="newPassword"
                rules="min:4|max:80">
                <b-input-group class="input-group-merge">
                  <b-form-input id="account-new-password" v-model="newPasswordValue" :type="passwordFieldTypeNew"
                    name="new-password" :placeholder="labelNewPassword" />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group label-for="account-retype-new-password" :label="labelRetypeNewPassword">
              <validation-provider #default="{ errors }" mode="eager" name="RetypePassword" vid="retype-password"
                rules="min:4|max:80|confirmed:newPassword">
                <b-input-group class="input-group-merge">
                  <b-form-input id="account-retype-new-password" v-model="RetypePassword"
                    :type="passwordFieldTypeRetype" name="retype-password" :placeholder="labelRetypeNewPassword" />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconRetype" class="cursor-pointer"
                      @click="togglePasswordRetype" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- alert -->
          <b-col cols="12" class="mt-75">
            <b-alert :show="!sendEmail2" variant="warning" class="mb-50 p-1">
              {{ $t(`Para sua segurança, os dados da sua conta só poderão ser modificados mediante a confirmação de um
                            código enviado para seu e-mail.`)
              }}
              <div class="mt-1">
                <b-button size="sm" variant="danger" @click="sendCode">{{ $t(`Clique aqui para receber o código no
                                  e-mail`)
                }}</b-button>
              </div>
            </b-alert>
            <alert :text="textAlert" :color="colorAlert" :showAlert="showAlert"
              @changeVariableAlert="(val) => (showAlert = val)" class="mb-1" />
          </b-col>
          <!--/ alert -->

          <!-- buttons -->
          <b-col cols="12">
            <b-form-group :label="$t('Código de segurança')">
              <validation-provider #default="{ errors }" mode="eager" name="securityCode" vid="securityCode"
                rules="min:10|max:10">
                <b-form-input v-model="securityCode" name="code" :placeholder="$t('Digite o código de segurança')"
                  :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" type="submit" class="mt-1 mr-1"
              :disabled="invalid">
              {{ $t("Salvar alterações") }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mt-1">
              {{ $t("Redefinir") }}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, confirmed, password, min, max } from "@/libs/validations";
import { mapGetters, mapActions } from "vuex";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    localize,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",

      labelOldPassword: this.$i18n.t("Senha antiga"),
      labelNewPassword: this.$i18n.t("Nova senha"),
      labelRetypeNewPassword: this.$i18n.t("Digite novamente a nova senha"),

      sendEmail2: false,
      colorAlert: 'warning',
      showAlert: false,
      textAlert: '',
      securityCode: '',

      //validation
      required,
      confirmed,
      password,
      min,
      max,
    };
  },
  computed: {
    ...mapGetters(["returnUser", "returnLocale"]),
    changedForm() {
      return (
        (this.passwordValueOld != "" && this.newPasswordValue === RetypePassword) && this.securityCode.length == 10
      );
    },
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  watch: {
    returnLocale: function (oldVal, newVal) {
      localize(oldVal);
      this.labelOldPassword = this.$i18n.t("Senha antiga");
      this.labelNewPassword = this.$i18n.t("Nova senha");
      this.labelRetypeNewPassword = this.$i18n.t(
        "Digite novamente a nova senha"
      );
    },
  },
  methods: {
    ...mapActions(["userEditProfile", "recoverUserData"]),
    sendCode() {
      const data = {
        clientEmail: this.returnUser.email,
        clientName: this.returnUser.fullname
      }
      this.$store.dispatch('sendSecurityCode', data).then(resp => {
        this.sendEmail2 = true;
        this.colorAlert = 'success';
        this.showAlert = true;
        this.textAlert = resp.message;
      })
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
    clearForm() {
      this.passwordValueOld = "";
      this.newPasswordValue = "";
      this.RetypePassword = "";
    },
    saveChange() {
      this.$refs.settingPasswordForm.validate().then((success) => {
        if (success) {
          const data = {
            currentPassword: this.passwordValueOld,
            newPassword: this.RetypePassword,
            securityCode: this.securityCode
          };

          // if (this.returnUser.auth != "auth") {
          //   delete data.currentPassword;
          // }

          this.userEditProfile(data)
            .then((response) => {
              this.recoverUserData();
              this.clearForm();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "success",
                  title: this.$i18n.t("Dados alterados!"),
                  text: this.$i18n.t(
                    "As informações do seu perfil foram atualizadas."
                  ),
                },
              });
            })
            .catch((error) => {
              this.$refs.settingPasswordForm.setErrors(error.errors);
            });
        }
      });
    },
  },
};
</script>
