<template>
  <b-card>
    <validation-observer ref="walletProfileForm" #default="{ invalid }">
      <b-form class="mt-2" @submit.prevent="saveChange">
        <div class="mt-1">
          <label for="usdt-wallet">Minha Conta PIX</label>
          <validation-provider
            #default="{ errors }"
            mode="eager"
            name="pixAccount"
            vid="pixAccount"
            rules="min:9|max:100"
          >
            <b-form-input
              v-model="returnUser.pixAccount"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
        <!-- alert -->
        <b-col cols="12" class="mt-75">
          <b-alert :show="!sendEmail" variant="warning" class="mb-50 p-1">
            {{
              $t(`Para sua segurança, os dados da sua conta só poderão ser modificados mediante a confirmação de um
                        código enviado para seu e-mail.`)
            }}
            <div class="mt-1">
              <b-button size="sm" variant="danger" @click="sendCode">{{
                $t(`Clique aqui para receber o código no
                              e-mail`)
              }}</b-button>
            </div>
          </b-alert>
        </b-col>
        <!--/ alert -->
        <div class="mt-2">
          <alert
            :text="textAlert"
            :color="colorAlert"
            :showAlert="showAlert"
            @changeVariableAlert="(val) => (showAlert = val)"
            class="mb-1"
          />
          <b-form-group :label="$t('Código de segurança')">
            <validation-provider
              #default="{ errors }"
              mode="eager"
              name="securityCode"
              vid="securityCode"
              rules="min:10|max:10"
            >
              <b-form-input
                v-model="securityCode"
                name="code"
                :placeholder="$t('Digite o código de segurança')"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            variant="primary"
            type="submit"
            :disabled="invalid || loading"
          >
            <div v-if="!loading">
              {{ $t("Salvar alterações") }}
            </div>
            <div v-else>
              <b-spinner small type="grow"></b-spinner>
              {{ $t("Carregando...") }}
            </div>
          </b-button>
          <b-button variant="outline-secondary" class="ml-1">{{
            $t("Redefinir")
          }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { min, max } from "@/libs/validations";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      bitcoinWallet: "",
      min,
      max,
      showAlert: false,
      colorAlert: "warning",
      textAlert: "",
      loading: false,

      sendEmail: false,
      securityCode: "",
    };
  },
  computed: mapGetters(["returnUser"]),
  methods: {
    ...mapActions(["userEditProfile", "recoverUserData"]),
    sendCode() {
      const data = {
        clientEmail: this.returnUser.email,
        clientName: this.returnUser.fullname,
      };
      this.$store.dispatch("sendSecurityCode", data).then((resp) => {
        this.sendEmail = true;
        this.colorAlert = "success";
        this.showAlert = true;
        this.textAlert = resp.message;
      });
    },
    saveChange() {
      this.$refs.walletProfileForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          const data = {
            addressBTC: this.returnUser.addressBTC,
            addressBNB: this.returnUser.addressBNB,
            addressBUSD: this.returnUser.addressBUSD,
            addressDOGE: this.returnUser.addressDOGE,
            addressUSDT: this.returnUser.addressUSDT,
            pixAccount: this.returnUser.pixAccount,
            securityCode: this.securityCode,
          };
          this.userEditProfile(data)
            .then((response) => {
              this.recoverUserData();
              this.loading = false;
              this.showAlert = true;
              this.colorAlert = "success";
              this.textAlert = this.$i18n.t(
                "A sua conta PIX foi atualizada."
              );
            })
            .catch((error) => {
              this.$refs.walletProfileForm.setErrors(error);
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
